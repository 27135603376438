import React, { useContext } from 'react';
import { LangContext } from '../context/lang';
import Logo from '../components/Logo';
import SideMenu from '../components/Menu';
import FadeIn from 'react-fade-in';

interface ExhibitionsPageProps {
  translate: (key: string) => string
}

const ExhibitionsPage: React.FC<ExhibitionsPageProps> = ({ translate }) => {
    const lang = useContext(LangContext);
    const className = 'a-text';

    const exhibitions = () => {
        switch (lang.state.language) {
            case 'EN':
                return <div className={className}>
                    <table>
                        <tbody>
                            <tr>
                                <td>2023</td>
                                <td>„simply two“ exhibition participation in the Kornhaus and Mayers Kulturbad in Romanshorn</td>
                            </tr>
                            <tr>
                                <td>2016</td>
                                <td>
                                Exhibition at &Eacute;ph&eacute;m&egrave;res, Saanen -
                                Switzerland
                                </td>
                            </tr>
                            <tr>
                                <td>2015</td>
                                <td>
                                Exhibition & concert at Atelier Chalet Luchs, Sch&ouml;nried -
                                Switzerland
                                </td>
                            </tr>
                            <tr>
                                <td>2014</td>
                                <td>At art.fair, Cologne - Germany</td>
                            </tr>
                            <tr>
                                <td>2014</td>
                                <td>At Montra do Lidau, Macau - China</td>
                            </tr>
                            <tr>
                                <td>2013</td>
                                <td>At Galeria Aragon 232, Barcelona - Spain</td>
                            </tr>
                            <tr>
                                <td>2013</td>
                                <td>At art.fair, Cologne - Germany</td>
                            </tr>
                            <tr>
                                <td>2012</td>
                                <td>At art.fair, Cologne - Germany</td>
                            </tr>
                            <tr>
                                <td>2012</td>
                                <td>At Marbart 8, Marbella - Spain</td>
                            </tr>
                            <tr>
                                <td>2012</td>
                                <td>
                                At The National Art Center Tokyo,<br />
                                At Hiroshima Prefectural Art Museum<br />
                                At Kyoto Municipal Museum of Art, Japan
                                </td>
                            </tr>
                            <tr>
                                <td>2012</td>
                                <td>At Galeria Aragon 232, Barcelona - Spain</td>
                            </tr>
                            <tr>
                                <td>2012</td>
                                <td>At Kunstart, Bolzano - Italien</td>
                            </tr>
                            <tr>
                                <td>2011</td>
                                <td>At Lineart, Ghent - Belgien</td>
                            </tr>
                            <tr>
                                <td>2011</td>
                                <td>At Marbart 7, Marbella - Spain</td>
                            </tr>
                            <tr>
                                <td>2010</td>
                                <td>At Marbart 6, Marbella - Spain</td>
                            </tr>
                            <tr>
                                <td>2010</td>
                                <td>At Museo de Mijas Pueblo, Spain</td>
                            </tr>
                            <tr>
                                <td>2009</td>
                                <td>At Castillo del Bil Bil, Benalmadena - Spain</td>
                            </tr>
                            <tr>
                                <td>2007</td>
                                <td>At Buchinger, Marbella - Spain</td>
                            </tr>
                            <tr>
                                <td>2003</td>
                                <td>At Daimler Chrysler AG, Dresden</td>
                            </tr>
                            <tr>
                                <td>2002</td>
                                <td>At Kunsthalle T3, Dresden</td>
                            </tr>
                            <tr>
                                <td>2001</td>
                                <td>Dipom exposition at the Oktogon der HfBK Dresden</td>
                            </tr>
                            <tr>
                                <td>2000</td>
                                <td>
                                At Fr&uuml;hlingssalon der HfBK Dresden (Paintings and
                                Performance)
                                </td>
                            </tr>
                            <tr>
                                <td>1998</td>
                                <td>
                                At Fr&uuml;hlingssalon der HfBK Dresden (Installation in public
                                space)
                                </td>
                            </tr>
                            <tr>
                                <td>1998</td>
                                <td>At Jenaer Kunstverein</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

            case 'DE':
                return <div className={className}>
                    <table>
                        <tbody>
                            <tr>
                                <td>2023</td>
                                <td>„simply two“ Austellungsbeteiligung im Kornhaus und Mayers Kulturbad in Romanshorn</td>
                            </tr>
                            <tr>
                                <td>2016</td>
                                <td>
                                    Ausstellung in &Eacute;ph&eacute;m&egrave;res, Saanen - Schweiz
                                </td>
                            </tr>
                            <tr>
                                <td>2015</td>
                                <td>
                                    Ausstellung & Konzert im Atelier Chalet Luchs, Sch&ouml;nried -
                                    Schweiz
                                </td>
                            </tr>
                            <tr>
                                <td>2014</td>
                                <td>Vertreten auf art.fair, K&ouml;ln</td>
                            </tr>
                            <tr>
                                <td>2014</td>
                                <td>Beteiligung in Montra do Lidau, Macau - China</td>
                            </tr>
                            <tr>
                                <td>2013</td>
                                <td>Beteiligung in Galeria Aragon 232, Barcelona - Spanien</td>
                            </tr>
                            <tr>
                                <td>2013</td>
                                <td>Vertreten auf art.fair, K&ouml;ln</td>
                            </tr>
                            <tr>
                                <td>2012</td>
                                <td>Vertreten auf art.fair, K&ouml;ln</td>
                            </tr>
                            <tr>
                                <td>2012</td>
                                <td>Vertreten auf Marbart 8, Marbella - Spanien</td>
                            </tr>
                            <tr>
                                <td>2012</td>
                                <td>
                                    Beteiligung in: The National Art Center Tokyo,<br />
                                    Hiroshima Prefectural Art Museum<br />
                                    Kyoto Municipal Museum of Art, Japan
                                </td>
                            </tr>
                            <tr>
                                <td>2012</td>
                                <td>Beteiligung in Galeria Aragon 232, Barcelona - Spanien</td>
                            </tr>
                            <tr>
                                <td>2012</td>
                                <td>Vertreten auf der Kunstart, Bolzano - Italien</td>
                            </tr>
                            <tr>
                                <td>2011</td>
                                <td>Vertreten auf der Lineart, Ghent - Belgien</td>
                            </tr>
                            <tr>
                                <td>2011</td>
                                <td>Vertreten auf der Marbart 7, Marbella - Spanien</td>
                            </tr>
                            <tr>
                                <td>2010</td>
                                <td>Vertreten auf der Marbart 6, Marbella - Spanien</td>
                            </tr>
                            <tr>
                                <td>2010</td>
                                <td>Beteiligung in Museo de Mijas Pueblo, Spanien</td>
                            </tr>
                            <tr>
                                <td>2009</td>
                                <td>
                                    Einzelausstellung im Castillo del Bil Bil, Benalmadena - Spanien
                                </td>
                            </tr>
                            <tr>
                                <td>2007</td>
                                <td>Einzelausstellung in Buchinger, Marbella - Spanien</td>
                            </tr>
                            <tr>
                                <td>2003</td>
                                <td>Einzelausstellung in Daimler Chrysler AG, Dresden</td>
                            </tr>
                            <tr>
                                <td>2002</td>
                                <td>Beteiligung in Kunsthalle T3, Dresden</td>
                            </tr>
                            <tr>
                                <td>2001</td>
                                <td>Diplomausstellung im Oktogon der HfBK Dresden</td>
                            </tr>
                            <tr>
                                <td>2000</td>
                                <td>
                                    Beteiligung im Fr&uuml;hlingssalon der HfBK Dresden (Malerei und
                                    Performance)
                                </td>
                            </tr>
                            <tr>
                                <td>1998</td>
                                <td>
                                    Beteiligung im Fr&uuml;hlingssalon der HfBK Dresden
                                    (Installation im &ouml;ffentlichen Raum)
                                </td>
                            </tr>
                            <tr>
                                <td>1998</td>
                                <td>Beteiligung im Jenaer Kunstverein</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            case 'FR': 
                return <div className={className}>
                    <table>
                        <tbody>
                            <tr>
                                <td>2023</td>
                                <td>„simply two“ participation à une exposition au Kornhaus et au Mayers Kulturbad à Romanshorn</td>
                            </tr>
                            <tr>
                                <td>2016</td>
                                <td>
                                Exposition au &Eacute;ph&eacute;m&egrave;res, Saanen - Suisse
                                </td>
                            </tr>
                            <tr>
                                <td>2015</td>
                                <td>
                                Exposition & concert au Atelier Chalet Luchs, Sch&ouml;nried -
                                Suisse
                                </td>
                            </tr>
                            <tr>
                                <td>2014</td>
                                <td>Au art.fair, Cologne - Allemagne</td>
                            </tr>
                            <tr>
                                <td>2014</td>
                                <td>Au Montra do Lidau, Macau - Chine</td>
                            </tr>
                            <tr>
                                <td>2013</td>
                                <td>Au Galeria Aragon 232, Barcelona - Espagne</td>
                            </tr>
                            <tr>
                                <td>2013</td>
                                <td>Au art.fair, Cologne - Allemagne</td>
                            </tr>
                            <tr>
                                <td>2012</td>
                                <td>Au art.fair, Cologne - Allemagne</td>
                            </tr>
                            <tr>
                                <td>2012</td>
                                <td>Au Marbart 8, Marbella - Espagne</td>
                            </tr>
                            <tr>
                                <td>2012</td>
                                <td>
                                Au The National Art Center Tokyo,<br />
                                Au Hiroshima Prefectural Art Museum<br />
                                Au Kyoto Municipal Museum of Art, Jap0n
                                </td>
                            </tr>
                            <tr>
                                <td>2012</td>
                                <td>A la Galeria Aragon 232, Barcelona - Espagne</td>
                            </tr>
                            <tr>
                                <td>2012</td>
                                <td>Au Kunstart, Bolzano - Italie</td>
                            </tr>
                            <tr>
                                <td>2011</td>
                                <td>Au Lineart, Ghent - Belgien</td>
                            </tr>
                            <tr>
                                <td>2011</td>
                                <td>A la Marbart 7, Marbella - Espagne</td>
                            </tr>
                            <tr>
                                <td>2010</td>
                                <td>A la Marbart 6, Marbella - Espagne</td>
                            </tr>
                            <tr>
                                <td>2010</td>
                                <td>Au Museo de Mijas Pueblo, Espagne</td>
                            </tr>
                            <tr>
                                <td>2009</td>
                                <td>Au Castillo del Bil Bil, Benalmadena - Espagne</td>
                            </tr>
                            <tr>
                                <td>2007</td>
                                <td>A la Buchinger, Marbella - Espagne</td>
                            </tr>
                            <tr>
                                <td>2003</td>
                                <td>A la Daimler Chrysler AG, Dresden - Allemagne</td>
                            </tr>
                            <tr>
                                <td>2002</td>
                                <td>A la Kunsthalle T3, Dresden - Allemagne</td>
                            </tr>
                            <tr>
                                <td>2001</td>
                                <td>
                                Exposition du dipl&ocirc;me au the Oktogon der HfBK Dresden,
                                Allemagne
                                </td>
                            </tr>
                            <tr>
                                <td>2000</td>
                                <td>
                                Au Fr&uuml;hlingssalon der HfBK Dresden (Peinture et
                                Performance), Allemagne
                                </td>
                            </tr>
                            <tr>
                                <td>1998</td>
                                <td>
                                Au Fr&uuml;hlingssalon der HfBK Dresden (Installation dans
                                l'espace publique), Allemagne
                                </td>
                            </tr>
                            <tr>
                                <td>1998</td>
                                <td>Au Jenaer Kunstverein, Allemagne</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
        
            default:
                return;
        }
    }

    return(
        <div className="p-container">            
            <SideMenu translate={translate} />
            <Logo />
            <FadeIn>
                <h1 className="a-headline">
                    {translate('sideMenuExhibitions')}
                </h1>
                {exhibitions()}
            </FadeIn>
        </div>
    );
}

export default ExhibitionsPage;